
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './Header.css';
import logo from '../logo.ico'; // Import your logo image

function Header({ limit }) {
  console.log("Header component rendered");

  const [balance, setBalance] = useState(JSON.parse(sessionStorage.getItem("balance")));
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const username = userData?.name;
  const companyID = userData?.CompanyID;
  const mainagentID = userData?.MainAgentID;
  const subagentID = userData?.SubAgentID;
  console.log(userData);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    console.log(mainagentID);
    console.log(subagentID);
    axios
      .post("http://localhost:8888/backend/api/modules/getbalance", {
        cmpid: companyID,
        mnaid: mainagentID,
        sbagid: subagentID,
      })
      .then((response) => {
        console.log(response.data);
        const newBalance = response.data.balance;
        console.log(newBalance);
        setBalance(newBalance);
        sessionStorage.setItem("balance", JSON.stringify(newBalance));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [companyID, mainagentID, subagentID]);

  return (
    <div className="header">
      <h2>
        <img src={logo} alt="Logo" className="logo" /> {username}
      </h2>
      <div className="agent-limit">Agent Limit: ${balance}</div>
    </div>
  );
}

export default Header;

