
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate


const CommissionRate = () => {
  const storedUserData = JSON.parse(sessionStorage.getItem("userDetails")) || {};

  const [companyID, setCompanyID] = useState('');
  const [mainAgentID, setMainAgentID] = useState('');
  const [subAgentID, setSubAgentID] = useState('');
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [rate, setRate] = useState('');
  const navigate = useNavigate(); // Updated to useNavigate

  useEffect(() => {
    setCompanyID(storedUserData.CompanyID);
    setMainAgentID(storedUserData.MainAgentID);
   // setSubAgentID(storedUserData.ID);
    fetchAgents();
  }, [storedUserData]);

  const fetchAgents = async () => {
    try {
      const response = await axios.post('http://gogolow.com/backend/api/modules/commissionrate', { mainagent: mainAgentID });
      const data = response.data;
      if (data.subagents) {
        setAgents(data.subagents);
      } else {
        console.error('Failed to fetch agents', data.message);
      }
    } catch (error) {
      console.error('Error fetching agents', error);
    }
  };

  const handleAgentChange = (e) => {
    const selected = e.target.value;
    setSelectedAgent(selected);
    const agent = agents.find(agent => agent.name === selected);
     console.log(agent);
    if (agent) {
     
      setSubAgentID(agent.ID);
    }
  };

  const handleRateChange = (e) => {
    setRate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rate.length === 0) {
      alert('Commission rate is not valid!');
      return;
    }

    try {
      const response = await axios.post('http://gogolow.com/backend/api/modules/savecommrate', {
        cmpid: companyID,
        mnaid: mainAgentID,
        sbagid: subAgentID,
        cmr: rate,
      });
      const data = response.data;
      console.log(data);
      if (data.status === 'success') {
        alert('Rate saved successfully');
        setSelectedAgent('');
        setRate('');
        navigate('/Dashboard', { replace: true });
          window.location.reload(); // Reload the page to clear history
      } else {
        
        alert(data.message);
      }
    } catch (error) {
      console.error('Error saving rate', error);
    }
  };

  return (
    <div>
      <h2>Commission Rate</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="agentList">Agent</label>
          <select id="agentList" value={selectedAgent} onChange={handleAgentChange}>
            <option value="">Select Agent</option>
            {agents.map(agent => (
              <option key={agent.ID} value={agent.name}>
                {agent.name} - {agent.ID}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="commmrate">Rate</label>
          <input
            type="text"
            id="commmrate"
            value={rate}
            onChange={handleRateChange}
          />
        </div>
        <button type="submit">Save Rate</button>
      </form>
    </div>
  );
};

export default CommissionRate;

