

import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Layout.css';

const Layout = React.memo(({ limit }) => {
  console.log("Layout component rendered");

  return (
    <div className="layout">
      <Header limit={limit} />
      <div className="content">
        <Sidebar />
        <div className="main-content">
          <Outlet /> {/* This renders the child route components */}
          <Footer />
        </div>
      </div>
    </div>
  );
});

export default Layout;
