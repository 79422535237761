import React, { useState } from 'react';
import { TextField, Button, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate
import './Settings.css';

const Settings = () => {
  const usrdetails = JSON.parse(sessionStorage.getItem("userDetails")) || {};
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Updated to useNavigate

  const handlePasswordChange = (event) => {
    setPasswords({
      ...passwords,
      [event.target.name]: event.target.value
    });
  };

  const changePassword = (event) => {
    event.preventDefault();
    if (passwords.newPassword !== passwords.confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    const data = {
      currentPassword: passwords.currentPassword,
      newPassword: passwords.newPassword,
      SubAgentID: usrdetails.SubAgentID
    };

    axios.post('http://gogolow.combackend/api/modules/changePassword.php', data)
      .then(response => {
        console.log(response.data);
        const responsedata = response.data;
        if (responsedata.status === 'success') {
          alert('Password has been changed successfully');
          setPasswords({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
          });
          sessionStorage.clear(); // Clear session storage
          navigate('/login'); // Redirect to login page
          window.location.reload(); // Reload the page to clear history
        } else {
          setError(response.data.message);
        }
      })
      .catch(error => {
        setError('Error changing password');
        console.error('Error:', error);
      });
  };

  return (
    <Paper className="settings-container">
      <Typography variant="h5" style={{ marginTop: '20px' }}>Change Password</Typography>
      <form onSubmit={changePassword}>
        <TextField
          label="Current Password"
          name="currentPassword"
          type="password"
          value={passwords.currentPassword}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="New Password"
          name="newPassword"
          type="password"
          value={passwords.newPassword}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirm New Password"
          name="confirmPassword"
          type="password"
          value={passwords.confirmPassword}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" type="submit">
          Change Password
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </form>
    </Paper>
  );
};

export default Settings;
