import React, { useState, useEffect } from 'react';
import './LastTransactions.css';
import axios from 'axios';

const LastTransactions = () => {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails")) || {};
    const subAgentID = userDetails.SubAgentID || '';
    const [searchTerm, setSearchTerm] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTransactions = async () => {
        setIsLoading(true);
        setError(null);

        setTimeout(async () => {
            try {
                console.log('Fetching transactions...');
                const response = await axios.post('http://gogolow.com/backend/api/modules/smart_search', {
                    search: searchTerm,
                    subAgentID: subAgentID,
                });

                console.log('Response:', response.data);

                const data = response.data;

                if (data && data.Returned) {
                    // Log the data to verify the structure
                    console.log('Returned Data:', data.Returned);

                    setTransactions(data.Returned.map(item => {
                        console.log('Mapping item:', item); // Log each item for debugging
                        return {
                            id: item.RemittanceID, // Use the correct property name
                            dateCreated: item.DateSent,
                            receiverName: item.ReceiverName,
                            phoneNumber: item.PhoneNumber,
                            country: item.payingCity,
                            amount: item.Amount,
                            serviceType: item.servicetype,
                            bankName: item.BankName,
                            accountNumber: item.AccountNumber,
                            status: item.Status.toUpperCase() // Ensure status is in uppercase for consistency
                        };
                    }));
                    if (data.Returned.length === 0) {
                        setError('No results found.');
                    }
                } else {
                    setTransactions([]);
                    setError('No results found.');
                }
            } catch (error) {
                setError('Network error. Please try again later.');
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        }, 5000);
    };

    useEffect(() => {
        if (searchTerm) {
            fetchTransactions();
        } else {
            setTransactions([]);
        }
    }, [searchTerm, subAgentID]);

    const handleVoidTransaction = async (transactionID) => {
        const transaction = transactions.find(t => t.id === transactionID);
        if (!transaction) {
            setError('Transaction not found.');
            return;
        }

        if (transaction.status === 'VOID') {
            alert('Transaction was already voided.');
            return;
        } else if (transaction.status === 'PAID') {
            alert('Transaction cannot be voided.');
            return;
        }

        try {
            console.log('Void Transaction - TransactionID:', transactionID);
            console.log('Void Transaction - SubAgentID:', subAgentID);

            const response = await axios.post('http://gogolow.com/backend/api/modules/void_transaction', {
                transactionID: transactionID,
                SubAgentID: subAgentID,
            });
            const data = response.data;

            console.log(data);

            if (data.status === 'success') {
                alert(data.message);
                fetchTransactions(); // Refetch the transactions after voiding
            } else {
                setError('Failed to void the transaction.');
            }
        } catch (error) {
            setError('Network error. Please try again later.');
            console.error('Error:', error);
        }
    };

    return (
        <div className="LastTransactions">
            <h2>Look Up Transactions</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by customer name or mobile number"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            {isLoading && <div className="loading-spinner"></div>}
            {error && <p>Error: {error}</p>}
            {!isLoading && transactions.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            <th>Date Created</th>
                            <th>Receiver Name</th>
                            <th>Phone Number</th>
                            <th>Country</th>
                            <th>Amount</th>
                            <th>Service Type</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.dateCreated}</td>
                                <td>{transaction.receiverName}</td>
                                <td>{transaction.phoneNumber}</td>
                                <td>{transaction.country}</td>
                                <td>{transaction.amount}</td>
                                <td>{transaction.serviceType}</td>
                                <td>{transaction.bankName}</td>
                                <td>{transaction.accountNumber}</td>
                                <td>{transaction.status}</td>
                                <td>
                                    <button 
                                        onClick={() => handleVoidTransaction(transaction.id)} 
                                        disabled={transaction.status === 'PAID' || transaction.status === 'VOID'}
                                        style={{
                                            backgroundColor: transaction.status === 'PAID' || transaction.status === 'VOID' ? 'gray' : '',
                                            cursor: transaction.status === 'PAID' || transaction.status === 'VOID' ? 'not-allowed' : 'pointer'
                                        }}
                                    >
                                        Void
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {!isLoading && transactions.length === 0 && !error && <p>No transactions to display.</p>}
        </div>
    );
};

export default LastTransactions;
