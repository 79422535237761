import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Paper } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';





const CommissionReport = ({ userData }) => {
  const [agentID, setAgentID] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUserData = JSON.parse(sessionStorage.getItem("userDetails")) || {};
    console.log(storedUserData);
    // Set agentID based on userData
    if (storedUserData.AgentType === 'MainCompany') {
      setAgentID(storedUserData.CompanyID);
    } else if (storedUserData.AgentType === 'Branch') {
      setAgentID(storedUserData.MainAgentID);
    } else if (storedUserData.AgentType === 'Agent') {
      setAgentID(storedUserData.SubAgentID);
    }
  }, []);

  const fetchReport = async () => {
    const storedUserData = JSON.parse(sessionStorage.getItem("userDetails")) || {};
    let url = '';
    const params = {
      subject_id: agentID,
      fromdate: fromDate.toISOString().split('T')[0],
      todate: toDate.toISOString().split('T')[0],
    };

    if (storedUserData.AgentType === 'MainCompany') {
      url = 'http://gogolow.com/backend/api/modules/companycommission';
    } else if (storedUserData.AgentType === 'Branch') {
      url = 'http://gogolow.com/backend/api/modules/mainagentcommission';
    } else if (storedUserData.AgentType === 'Agent') {
      url = 'http://gogolow.com/backend/api/modules/agentcommission';
    }

    try {
      console.log('Fetching report with params:', params, 'URL:', url);
      const response = await axios.get(url, { params });
      console.log('Response:', response.data);
      setReport(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching report:', error);
      setError('Failed to fetch report. Please check your inputs.');
      setReport(null);
    }
  };

  const handleFetchReport = () => {
    if (agentID && fromDate && toDate) {
      fetchReport();
    } else {
      setError('Please fill in all fields.');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper style={{ padding: '16px', margin: '16px' }}>
        <Typography variant="h4">Commission Report</Typography>
        <div>
          <TextField
            label="Agent ID"
            value={agentID}
            onChange={(e) => setAgentID(e.target.value)}
            disabled // Disable the input field since agentID is set based on userData
            fullWidth
            margin="normal"
          />
        </div>
        <div>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
        </div>
        <div>
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
        </div>
        <Button variant="contained" color="primary" onClick={handleFetchReport} style={{ marginTop: '16px' }}>
          Fetch Report
        </Button>
        {error && <Typography color="error">{error}</Typography>}
        {report && (
          <div style={{ marginTop: '16px' }}>
            <Typography variant="h6">Commission Report </Typography>
            <Typography>Commission Total: ${report.comm}</Typography>
            
          </div>
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default CommissionReport;
