
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';


function Sidebar() {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const isAgent = userData?.AgentType === 'Agent';
  const [isDashboardOpen, setDashboardOpen] = useState(false);
  const [isComplaintsOpen, setComplaintsOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);

  return (
    <div className="sidebar">
      {/*<h2>Mabrook Express</h2>*/}
      <ul>
      <li>
       <button onClick={() => setDashboardOpen(!isDashboardOpen)}>
            Dashboard {isDashboardOpen ? '▼' : '►'}
          </button>
          {isDashboardOpen && (
            <ul>
    
        <li><Link to="/send-money">Send Money</Link></li>
        <li><Link to="/last-transactions">Look up Transactions</Link></li>
        <li><Link to="/incoming-remittance">Incoming Remittance</Link></li>
        <li><Link to="/commission-report">Commission Report</Link></li>
        <li><Link to="/agents">Agents</Link></li>
          </ul>
          )}
        </li>
     {/*   <li>
          <button onClick={() => setComplaintsOpen(!isComplaintsOpen)}>
            Complaints {isComplaintsOpen ? '▼' : '►'}
          </button>
          {isComplaintsOpen && (
            <ul>
              <li><Link to="/complaints/new">New</Link></li>
              <li><Link to="/complaints/status">Complaints Status</Link></li>
              <li><Link to="/complaints/rate">Commission Rate</Link></li>
            </ul>
          )}
        </li>*/}
        <li>
          <button onClick={() => setAccountOpen(!isAccountOpen)}>
            Account {isAccountOpen ? '▼' : '►'}
          </button>
          {isAccountOpen && (
            <ul>
            {!isAgent && <li><a href="/account/add-agent">Add Agent</a></li>}
              
              <li><Link to="/account/settings">Settings</Link></li>
              {!isAgent && <li><a href="/account/exchange-rate">Exchange Rate</a></li>}
              {!isAgent && <li><a href="/account/commission-rate">Commission Rate</a></li>}
              {!isAgent && <li><a href="/account/update-agency-limit">Update Agency Limit</a></li>}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}
 // <li><Link to="/account/commission-rate">Commission Rate</Link></li>

export default Sidebar;

