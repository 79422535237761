

import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = React.memo(({ limit }) => {
 

  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
   if(userData.AgentType === 'Agent'){
    // I want to hide hide bar account/commission-rate
   }
  const username = userData?.name;

  sessionStorage.setItem(
    "CompInfo",
    JSON.stringify(JSON.parse(sessionStorage.getItem("userDatails")))
  );

  useEffect(() => {
    console.log("useEffect triggered");

    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      {/*<p>Dashboard component</p>*/}
      {/* <Typography variant="h4" gutterBottom>
        Welcome, {username}!
      </Typography>
      <Container>
        <Alert severity="success">
          You have successfully logged into your account!
        </Alert>
        <Button variant="contained" href="/logout">
          Logout
        </Button>
      </Container> */}
    </div>
  );
});

export default Dashboard;

